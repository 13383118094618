import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ENDPOINTS } from '../constants/endpoints';
import { HTTP_METHODS } from '../constants/http';

export type ErrorResponse = {
  status: number;
  message: string;
  exception: AxiosError;
};

export type Query = {
  [key: string]: string | number | boolean | any;
};

type RequestParams = {
  url: string;
  httpMethod: string;
  queryParams?: Query;
  body?: any;
  authToken?: string;
  includeTokenInBody?: boolean;
  responseType?: AxiosRequestConfig['responseType'];
  headers?: Record<string, string>; // Add this line to allow custom headers
};

export type ApiResponse<T> = {
  data: T;
  status: number;
};

const getErrorMessages = (error: AxiosError): string => {
  if (!error.response) {
    return 'Network Error';
  }

  if (error.response.data && typeof error.response.data === 'string') {
    return error.response.data;
  }

  return error.message || 'An unknown error occurred';
};

const createErrorResponse = (error: AxiosError): ErrorResponse => {
  return {
    status: error.response?.status || 500,
    message: getErrorMessages(error),
    exception: error,
  };
};

const makeRequestAsync = async <T>({
  url,
  httpMethod,
  queryParams,
  body,
  authToken,
  includeTokenInBody = false,
  responseType = 'json',
  headers = {}, // Default to an empty object if no headers are provided
}: RequestParams): Promise<ApiResponse<T> | ErrorResponse> => {
  // Set Authorization header if token is provided and should not be in the body
  if (authToken && !includeTokenInBody) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  // Automatically handle Content-Type based on body type (FormData for multipart requests)
  if (!(body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  const requestBody = includeTokenInBody ? { ...body, jwtToken: authToken } : body;

  const request: AxiosRequestConfig = {
    url,
    method: httpMethod,
    params: queryParams,
    data: requestBody,
    headers,
    responseType,
  };

  try {
    const response: AxiosResponse<T> = await axios(request);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return createErrorResponse(error as AxiosError);
  }
};

// Function to upload images for a specific event
const uploadEventImages = async (
  eventId: string,
  images: File[],
  isPrivate: boolean,
  authToken: string,
): Promise<ApiResponse<{ message: string }> | ErrorResponse> => {
  const formData = new FormData();
  images.forEach(image => formData.append('images', image));
  formData.append('isPrivate', String(isPrivate));

  return makeRequestAsync({
    url: `${ENDPOINTS.EVENTS.UPLOAD_IMAGES(eventId)}`,
    httpMethod: HTTP_METHODS.POST,
    body: formData,
    authToken,
  });
};

// Fetch all image IDs for a specific event
const fetchEventImageIds = async (
  eventId: string,
  authToken: string,
): Promise<ApiResponse<{ id: string }[]> | ErrorResponse> => {
  return makeRequestAsync({
    url: `${ENDPOINTS.EVENTS.GET_IMAGES(eventId).replace(':eventId', eventId)}`, // Construct URL directly
    httpMethod: HTTP_METHODS.GET,
    authToken,
    queryParams: { includePrivate: false },
  });
};

// Fetch a specific image by ID
const fetchImageById = async (
  imageId: string,
  authToken: string,
): Promise<ApiResponse<ArrayBuffer> | ErrorResponse> => {
  return makeRequestAsync({
    url: `${ENDPOINTS.EVENTS.GET_IMAGE_BY_ID(imageId).replace(':imageId', imageId)}`, // Construct URL directly
    httpMethod: HTTP_METHODS.GET,
    authToken,
    responseType: 'arraybuffer', // Specify arraybuffer for binary data
  });
};

// Function to handle user login
const loginUser = async (
  username: string,
  password: string,
): Promise<ApiResponse<{ token: string }> | ErrorResponse> => {
  return makeRequestAsync<{ token: string }>({
    url: ENDPOINTS.ECHO.GET_ONE,
    httpMethod: HTTP_METHODS.GET,
    body: { username, password },
  });
};

export const deleteEvent = async (eventId: string, token: string) => {
  return await makeRequestAsync({
    url: `${ENDPOINTS.EVENTS.DELETE(eventId)}`,
    httpMethod: 'DELETE',
    authToken: token,
  });
};

const apiService = {
  createErrorResponse,
  makeRequestAsync,
  loginUser,
  deleteEvent,
  fetchEventImageIds, // New function to get image IDs
  fetchImageById, // New function to get binary image data
  uploadEventImages, // New function to upload images as multipart/form-data
};

export default apiService;
