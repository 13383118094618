export const ROUTE_PATHS = {
  HOME: '/',
  GALLERY: '/gallery',
  ECHO: '/echo',
  LOGIN: '/login',
  SIGNUP: '/signup',
  TICKET_INFO: '/trip',
  CONFIRMATION_CODE: '/confirmation-code',
  ACCOUNT_SETTINGS: '/account-settings',
  EVENT_DETAILS: '/event/:id',
  CREATE_EVENT: '/create-event',
  YOUR_EVENTS: '/your-events',
  EDIT_EVENT: '/event/edit/:id',
  PAYMENT: '/payment/:id',
};
