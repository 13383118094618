import { Suspense, FC, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants/routePaths';
import Loader from '../shared/Loader';
import EchoComponent from '../echo/EchoComponent';

const Home = lazy(() => import('../../pages/home/Home'));
const Login = lazy(() => import('../login/Login'));
const Signup = lazy(() => import('../login/Signup'));
const ConfirmationCodePage = lazy(() => import('../confirmation/ConfirmationCodePage'));
const AccountSettingsPage = lazy(() => import('../accountSettings/AccountSettingsPage'));
const EventDetailsPage = lazy(() => import('../../pages/events/EventDetailsPage'));
const ErrorPage = lazy(() => import('..//error/ErrorPage')); // Lazy load the ErrorPage
const CreateEventForm = lazy(() => import('../createEvent/CreateEventForm')); // Lazy load the create event form
const YourEvents = lazy(() => import('../../pages/events/YourEvents')); // Import YourEventsPage
const EditEventPage = lazy(() => import('../../pages/events/EditEventPage')); // Lazy load the EditEventPage

const CustomRouterProvider: FC = () => {
  const handleLogin = () => {
    // Handle login logic here
  };
  const router = createBrowserRouter([
    {
      path: ROUTE_PATHS.HOME,
      element: (
        <Suspense fallback={<Loader />}>
          <Home />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATHS.LOGIN,
      element: (
        <Suspense fallback={<Loader />}>
          <Login onLogin={handleLogin} />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATHS.SIGNUP,
      element: (
        <Suspense fallback={<Loader />}>
          <Signup />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATHS.CONFIRMATION_CODE,
      element: (
        <Suspense fallback={<Loader />}>
          <ConfirmationCodePage />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATHS.ECHO,
      element: (
        <Suspense fallback={<Loader />}>
          <EchoComponent />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATHS.ACCOUNT_SETTINGS,
      element: (
        <Suspense fallback={<Loader />}>
          <AccountSettingsPage />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATHS.EVENT_DETAILS,
      element: (
        <Suspense fallback={<Loader />}>
          <EventDetailsPage />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATHS.CREATE_EVENT,
      element: (
        <Suspense fallback={<Loader />}>
          <CreateEventForm />
        </Suspense>
      ),
    },

    {
      path: ROUTE_PATHS.YOUR_EVENTS,
      element: (
        <Suspense fallback={<Loader />}>
          <YourEvents />
        </Suspense>
      ),
    },

    {
      path: ROUTE_PATHS.EDIT_EVENT,
      element: (
        <Suspense fallback={<Loader />}>
          <EditEventPage />
        </Suspense>
      ),
    },

    {
      path: '*',
      element: (
        <Suspense fallback={<Loader />}>
          <ErrorPage />
        </Suspense>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default CustomRouterProvider;
